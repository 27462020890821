<template>
  <!--    Role Form-->
  <validation-observer
    ref="roleForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateRoleMode?'Update':'Create'} Role`"
      :show="roleModalOpened"
      :show-overlay="deletingRole"
      @onClose="closeRoleModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedRoleId"
            name="roles"
            placeholder="All Roles"
            :options="roles"
            label-name="name"
            value-name="id"
            @input="populateRoleForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            name="delete_role"
            icon="TrashIcon"
          >
            <b-dropdown-item
              id="cd_role"
              @click="deleteRole()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            name="role_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetRoleForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="role.name"
        name="role_name"
        placeholder="Enter role name"
        label="Role"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeRoleModal()"
          />
          <lenon-button
            name="save_role"
            :label="updateRoleMode?'Update':'Create'"
            :disabled="invalid"
            :loading="roleLoading"
            loading-text="Loading..."
            @onClick="updateRoleMode?updateRole():createRole()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import {
  CREATE_ROLE_M,
  DELETE_ROLE_M,
  UPDATE_ROLE_M,
} from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'

export default {
  name: 'RoleSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      roleModalOpened: false,
      updateRoleMode: false,
      roleLoading: false,
      deletingRole: false,
      selectedRoleId: null,
      role: {
        id: null,
        name: '',
      },
    }
  },
  computed: {
    selectedRole() {
      return this.roles.find(ter => ter.id === this.selectedRoleId)
    },
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
  },
  watch: {
    modalOpened(opened) {
      this.roleModalOpened = opened
    },
  },
  methods: {
    // role
    resetRoleForm() {
      this.role = {
        id: null,
        name: '',
      }
      this.selectedRoleId = null
      this.updateRoleMode = false
      this.$refs.roleForm.reset()
    },
    populateRoleForm(role) {
      if (this.selectedRole) {
        this.updateRoleMode = true
        this.role = {
          ...this.selectedRole,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.role.permissions
      }else{
        this.resetRoleForm()
      }
    },
    closeRoleModal() {
      this.roleModalOpened = false
      this.$emit('modalClosed')
    },
    updateRole() {
      this.error = {}
      if (!this.role.id) {
        return
      }
      this.roleLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_ROLE_M,
        variables: { input: this.role },
      })
        .then(res => {
          this.showSuccess('Updated role successfully')
          this.roleLoading = false
          this.$store.commit('accountUsers/updateRole', res.data.updateRole)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update role')
          this.roleLoading = false
        })
    },
    createRole() {
      this.error = {}
      this.roleLoading = true
      this.$apollo.mutate({
        mutation: CREATE_ROLE_M,
        variables: { input: this.role },
      })
        .then(res => {
          this.showSuccess('Created role successfully')
          this.roleLoading = false
          this.$store.commit('accountUsers/addRole', res.data.createRole)
          this.resetRoleForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create role')
          this.roleLoading = false
        })
    },
    deleteRole() {
      if (!this.selectedRoleId) {
        this.showInfo('Please select a role')
        return
      }
      this.deletingRole = true
      this.$apollo.mutate({
        mutation: DELETE_ROLE_M,
        variables: { input: { id: this.selectedRoleId } },
      })
        .then(() => {
          this.deletingRole = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('accountUsers/removeRole', this.selectedRoleId)
          this.selectedRoleId = null
          this.resetRoleForm()
        })
        .catch(() => {
          this.showError('Failed to delete role')
          this.deletingRole = false
        })
    },
  },
}
</script>
