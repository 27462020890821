<template>
  <div class="account-users">
    <lenon-list-page
      title="Roles"
      :columns="columnsRole"
      :rows="roles"
      :table-loading="rolesTableLoading"
      show-search
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            name="manage_roles"
            icon="SettingsIcon"
            label="Roles"
            tool-tip-text="Mange Roles (CRUD)"
            @onClick="showRoleModal()"
          />
          <lenon-dropdown
            v-responsive="$store.getters['app/smallScreen']"
            left
          >
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Roles"
              @click="showRoleModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #action="{row}">
        <lenon-button
          :disabled="isAdmin(row.item.name)"
          icon="LockIcon"
          :icon-only="true"
          tool-tip-text="Assign permissions to role"
          variant="flat-primary"
          @onClick="showPermissionsModal(row.item)"
        />
      </template>
    </lenon-list-page>
    <lenon-list-page
      title="Users"
      :columns="columnsUser"
      :rows="users"
      :table-loading="tableLoading"
      show-search
      :show-profile-photo="true"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            name="manage_users"
            icon="SettingsIcon"
            label="Users"
            tool-tip-text="Manage Users (CRUD)"
            @onClick="showUserModal()"
          />
          <lenon-dropdown
            v-responsive="$store.getters['app/smallScreen']"
            left
          >
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Users"
              @click="showUserModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #first_name="{row}">
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>
      <template #action="{row}">
        <lenon-button
          :icon="row.item.active?'UserCheckIcon':'UserXIcon'"
          :icon-only="true"
          :tool-tip-text="row.item.active?'Active, click to deactivate':'Inactive, click to activate'"
          :variant="row.item.active?'flat-success':'flat-danger'"
          loading-text=""
          @onClick="activateUser(row.item.id,!row.item.active)"
        />
        <lenon-button
          icon="RefreshCwIcon"
          :icon-only="true"
          variant="flat-primary"
          tool-tip-text="Resend User Credentials"
          @onClick="resendUserCredentials(row.item.id)"
        />
      </template>
    </lenon-list-page>

    <user-setup
      :modal-opened="userModalOpened"
      @modalClosed="closeModal('user')"
    />
    <role-setup
      :modal-opened="roleModalOpened"
      @modalClosed="closeModal('role')"
    />
    <permissions
      :role="selectedRole"
      :modal-opened="permissionsModalOpened"
      @modalClosed="closeModal('permissions')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { ROLES_USERS_PERMISSIONS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import UserSetup from '@/views/admin/account-users/UserSetup.vue'
import RoleSetup from '@/views/admin/account-users/RoleSetup.vue'
import Permissions from '@/views/admin/account-users/Permissions.vue'
import { ACTIVATE_USER_M, RESEND_USER_CREDENTIALS_M } from '@/graphql/mutations'
import confirm from '@/lenon/mixins/confirm'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'

export default {
  name: 'StudentGrading',
  components: {
    LenonDropdownItem,
    LenonDropdown,
    Permissions,
    RoleSetup,
    UserSetup,
    LenonListPage,
    LenonButton,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      selectedRole: {},
      roleModalOpened: false,
      userModalOpened: false,
      tableLoading: false,
      rolesTableLoading: false,
      permissionsModalOpened: false,
      columnsRole: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: 'Role',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
    }
  },
  computed: {
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
    columnsUser() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'first_name',
          label: 'Full Name',
          sortable: show,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: show,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: show,
        },
        {
          key: 'action',
          label: 'Action',
        },
      ]
    },
  },
  mounted() {
    this.fetchRolesUsersPermissions()
  },
  methods: {
    resendUserCredentials(id) {
      this.confirmAction('Are you sure you want to resend user password?').then(res => {
        if (res) {
          this.tableLoading = true
          this.$apollo.mutate({
            mutation: RESEND_USER_CREDENTIALS_M,
            variables: {
              id,
            },
          })
            .then(res => {
              this.showSuccess('Sent successfully')
              this.tableLoading = false
            })
            .catch(res => {
              this.tableLoading = false
              this.showError('Failed to send credentials')
            })
        }
      })
    },
    isAdmin(role) {
      return role === 'Administrator'
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'role':
          this.roleModalOpened = false
          break
        case 'user':
          this.userModalOpened = false
          break
        case 'permissions':
          this.permissionsModalOpened = false
          break
      }
    },
    showRoleModal() {
      this.roleModalOpened = true
    },
    showUserModal() {
      this.userModalOpened = true
    },
    showPermissionsModal(role) {
      this.selectedRole = role
      this.permissionsModalOpened = true
    },
    activateUser(id, status) {
      this.tableLoading = true
      this.$apollo.mutate({
        mutation: ACTIVATE_USER_M,
        variables: {
          id,
          status,
        },
      })
        .then(res => {
          this.showSuccess(`${status ? 'Activated' : 'Deactivated'} successfully`)
          this.tableLoading = false
          this.$store.commit('accountUsers/updateUserStatus', {
            id,
            status,
          })
        })
        .catch(res => {
          this.tableLoading = false
          this.showError(`Failed to ${status ? 'Activate' : 'Deactivate'} user account`)
        })
    },
    fetchRolesUsersPermissions() {
      this.$apollo.query({ query: ROLES_USERS_PERMISSIONS_Q })
        .then(res => {
          this.$store.commit('accountUsers/setRolesUsersPermissions', res.data)
        })
    },
  },
}
</script>
